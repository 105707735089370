<template>
  <div class="page-boxed">
    <div class="boxed-content">
      <h1 v-if="title">{{ title }}</h1>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxedPage",
  props: ["title"],
};
</script>
