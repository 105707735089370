<template>
  <BoxedPage :title="title">
    <div class="auth">
      <form
        class="questions"
        @submit.prevent="answer()"
        v-if="mode === 'questions'"
      >
        <div class="question" v-for="q in questions" :key="q">
          <label>
            <p>{{ q }}</p>
            <input type="text" v-model="answers[q]" />
          </label>
        </div>
        <button>Entrer</button>
      </form>
      <form v-else @submit.prevent="login()">
        <input type="text" v-model="user.email" placeholder="Identifiant" />
        <input
          type="password"
          v-model="user.password"
          placeholder="Mot de passe"
        />
        <button>Go</button>
      </form>
    </div>
  </BoxedPage>
</template>

<script>
import BoxedPage from "../components/BoxedPage.vue";

export default {
  name: "Login",
  components: {
    BoxedPage,
  },
  props: ["forceMode"],
  data() {
    let redirect = "/";
    if (
      this.$route &&
      this.$route.params &&
      this.$route.params.redirectTo &&
      this.$route.params.redirectTo.name
    ) {
      redirect = {
        name: this.$route.params.redirectTo.name,
        path: this.$route.params.redirectTo.path,
        params: this.$route.params.redirectTo.params,
        query: this.$route.params.redirectTo.query,
      };
    }
    return {
      mode: this.$route.name === "login" ? "questions" : "login",
      title:
        this.$route.name === "login"
          ? "Quelques questions pour commencer"
          : "Connexion",
      questions: null,
      auth: false,
      data: null,
      answers: {},
      user: {},
      redirect: redirect,
    };
  },
  created() {
    this.getQuestions();
  },
  methods: {
    getQuestions() {
      this.api.Auth.getQuestions().then((authQuestions) => {
        this.questions = authQuestions;
      });
    },
    answer() {
      this.api.Auth.answerQuestions({ answers: this.answers })
        .then((data) => {
          this.api.setTokens(data);
          this.api.setCurrentUserPromise(this.api.User.me());
          this.$router.push(this.redirect);
        })
        .catch(() => {
          this.getQuestions();
        });
    },
    login() {
      this.api.Auth.login(this.user).then((data) => {
        this.api.setTokens(data);
        this.api.setCurrentUserPromise(this.api.User.me());
        this.$router.push(this.redirect);
      });
    },
  },
};
</script>
